/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    h3: "h3",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Signatu APIs are Open API compatible REST APIs."), "\n", React.createElement(_components.p, null, "API requests are authorized by ", React.createElement(_components.a, {
    href: "/docs/api/v0/oauth"
  }, "OAuth 2.0"), " tokens. You can manage your API credentials in your Signatu account."), "\n", React.createElement(_components.p, null, "The APIs are specified in ", React.createElement(_components.a, {
    href: "https://www.openapis.org/"
  }, "OpenAPI"), " version 3. See ", React.createElement(_components.a, {
    href: "http://swagger.io/tools/"
  }, "OpenAPI development tools"), " for additional information."), "\n", React.createElement(_components.h2, {
    id: "authorizing-with-oauth",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#authorizing-with-oauth",
    "aria-label": "authorizing with oauth permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Authorizing with OAuth"), "\n", React.createElement(_components.p, null, "All API requests must authorized with a valid API Key and a valid OAuth2 Bearer token."), "\n", React.createElement(_components.p, null, "Both your API Key and OAuth2 tokens are created user your account at https://signatu.com or using standard OAuth2 flows (see ", React.createElement(_components.a, {
    href: "/docs/api/v0/oauth"
  }, "OAuth"), ")."), "\n", React.createElement(_components.p, null, "If you need help creating an access token please contact ", React.createElement(_components.a, {
    href: "mailto:support@signatu.com"
  }, "support@signatu.com"), "."), "\n", React.createElement(_components.h3, {
    id: "scope",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scope",
    "aria-label": "scope permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scope"), "\n", React.createElement(_components.p, null, "Access tokens must be granted access to scope ", React.createElement(_components.code, null, "consent"), "."), "\n", React.createElement(_components.h3, {
    id: "x-api-key-http-header",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#x-api-key-http-header",
    "aria-label": "x api key http header permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "x-api-key"), " HTTP header"), "\n", React.createElement(_components.p, null, "Only requests to the ", React.createElement(_components.code, null, "v0"), " version of the Consent API require the ", React.createElement(_components.code, null, "x-api-key"), " header. The API Key is used to identify your account and associated account limits. For the Data Processing and Webhook APIs\nthe ", React.createElement(_components.code, null, "x-api-key"), " header is not required as Signatu will find the account from the ", React.createElement(_components.a, {
    href: "#authorization-http-header"
  }, "Authorization"), " token."), "\n", React.createElement(_components.p, null, "Note that while ", React.createElement(_components.code, null, "x-api-key"), " is not used to authorize access to the API (see ", React.createElement(_components.a, {
    href: "#authorization-http-header"
  }, "Authorization"), " below) you should not distribute your API Key."), "\n", React.createElement(_components.h3, {
    id: "authorization-http-header",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#authorization-http-header",
    "aria-label": "authorization http header permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "Authorization"), " HTTP header"), "\n", React.createElement(_components.p, null, "You can authorize HTTP requests using the ", React.createElement(_components.code, null, "Authorization"), " http header. The API expects\na ", React.createElement(_components.code, null, "Bearer"), " token, meaning that any client presenting the token is treated as authorized. You can create tokens in your Signatu account, or use standard OAuth2 Client Credentials Grant flow (see ", React.createElement(_components.a, {
    href: "https://tools.ietf.org/html/rfc6749"
  }, "RFC 6749"), ")."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "Authorization"), " HTTP header is set. The token is a ", React.createElement(_components.code, null, "Bearer"), " token, meaning any client with the token available can access the Policies associated with the user account. The token should hence be kept secret."), "\n", React.createElement(_components.h3, {
    id: "example-request",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#example-request",
    "aria-label": "example request permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Example request"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "$ curl https://api.signatu.com/consent/v0/consents \\\n  -H 'Authorization: Bearer XXXX' \\\n  -H 'x-api-key: 12908347192749238798'\n* Connected to localhost (api.signatu.com) (#0)\n> GET /consent/api/v0/... HTTP/1.1\n> Host: signatu.com\n> x-api-key: 12908347192749238798\n> Authorization: Bearer XXXX\n")), "\n", React.createElement(_components.h2, {
    id: "uri-formats",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#uri-formats",
    "aria-label": "uri formats permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "URI Formats"), "\n", React.createElement(_components.p, null, "URI references are all URIs on the format ", React.createElement(_components.code, null, "scheme://"), ". ", React.createElement(_components.code, null, "scheme"), " is according to the ", React.createElement(_components.a, {
    href: "http://www.iana.org/assignments/uri-schemes/uri-schemes.xhtml"
  }, "IANA specification"), "."), "\n", React.createElement(_components.p, null, "Query parameters are URI encoded. In Javascript, use ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent"
  }, React.createElement(_components.code, null, "encodeURIComponent()")), ". For example, ", React.createElement(_components.code, null, "https://foo.com"), " is encoded ", React.createElement(_components.code, null, "https%3A%2F%2Ffoo.com"), "."), "\n", React.createElement(_components.h2, {
    id: "namespace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#namespace",
    "aria-label": "namespace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Namespace"), "\n", React.createElement(_components.p, null, "Signatu also uses a ", React.createElement(_components.a, {
    href: "https://tools.ietf.org/html/rfc1737"
  }, "URN (RFC-1737)"), " prefix ", React.createElement(_components.code, null, "urn:signatu:"), " to refer to resources. For HTML resources, such as Privacy Policies, these URN references are set on HTML elements using the ", React.createElement(_components.code, null, "data-ref"), " attribute."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
